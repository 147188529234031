@import "https://cdn-uicons.flaticon.com/2.2.0/uicons-regular-rounded/css/uicons-regular-rounded.css";
:root {
  --h-4-font-family: "Poppins", Helvetica;
  --h-4-font-size: 36px;
  --h-4-font-style: normal;
  --h-4-font-weight: 700;
  --h-4-letter-spacing: 0px;
  --h-4-line-height: normal;
  --h1-font-family: "Poppins", Helvetica;
  --h1-font-size: 64px;
  --h1-font-style: normal;
  --h1-font-weight: 700;
  --h1-letter-spacing: 0px;
  --h1-line-height: 77px;
  --h2-font-family: "Poppins", Helvetica;
  --h2-font-size: 48px;
  --h2-font-style: normal;
  --h2-font-weight: 700;
  --h2-letter-spacing: 0px;
  --h2-line-height: normal;
  --h4-mobile-font-family: "Poppins", Helvetica;
  --h4-mobile-font-size: 30px;
  --h4-mobile-font-style: normal;
  --h4-mobile-font-weight: 700;
  --h4-mobile-letter-spacing: 0px;
  --h4-mobile-line-height: normal;
  --primary: #03577a;
  --secondary: #3da3db;
}

html, body {
  font-family: Poppins, Helvetica;
  font-size: 16px;
}

@media screen and (width <= 768px) {
  html {
    font-size: 14px;
  }
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}
/*# sourceMappingURL=index.c1213b90.css.map */
